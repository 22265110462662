<template>
	<div class="document-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !documentNo"
		         v-on:ok="deleteDocument"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Document</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalDocumentNo" class="font-weight-bold">Document No.</label>
					<input type="text" id="modalDocumentNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': documentNoInvalid}"
					       v-model="documentNo"
					       v-on:input="documentNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-75 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveDocument"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!document"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="supplierDocumentNo" class="font-weight-bold">Document No.</label>
					<input type="text" id="supplierDocumentNo" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localDocument.documentNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="name" class="font-weight-bold">Name</label>
					<input type="text" id="name" placeholder="Delivery Schedule"
					       class="form-control position-relative"
					       v-bind:class="invalidInputs.name"
					       v-bind:value="localDocument.name"
					       v-on:input="updateDocument('name', $event.target.value)">
				</div>
				<div class="container-file-upload position-relative w-100 mb-3 d-flex align-items-end">
					<div class="form-group position-relative w-75 mb-0 flex-grow-1">
						<label for="fileName" class="font-weight-bold">File</label>
						<input type="file" id="fileName" placeholder="File"
						       class="form-control position-relative"
						       v-bind:class="invalidInputs.fileName"
						       v-on:change="uploadDocument($event.target.files)">
					</div>
					<button type="button"
					        class="button btn btn-custom-light w-auto h-50 ml-3 d-flex align-items-center overflow-hidden"
					        v-on:click="downloadDocument">
						<font-awesome-icon class="icon mr-2" v-bind:icon="['fas', 'file-csv']"></font-awesome-icon>
						<span class="name font-weight-bold text-truncate">Download</span>
					</button>
				</div>
				<div class="form-group position-relative w-100">
					<label for="type" class="font-weight-bold">Type</label>
					<select id="type" class="form-control position-relative"
					        v-bind:class="invalidInputs.type"
					        v-bind:value="localDocument.type"
					        v-on:change="updateDocument('type', $event.target.value)">
						<option disabled hidden value=""></option>
						<option>image</option>
						<option>video</option>
						<option>text</option>
						<option>others</option>
					</select>
				</div>
				
				<div class="form-group position-relative w-100">
					<label for="descriptions" class="font-weight-bold">Descriptions</label>
					<textarea id="descriptions" rows="6" class="form-control position-relative"
					          v-model="localDocument.descriptions"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">Quote Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="Quote Not Yet Saved"
					       v-bind:value="localDocument.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localDocument.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localDocument.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">Quote Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="Quote Not Yet Created"
					       v-bind:value="localDocument.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localDocument.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localDocument.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import {BFormDatepicker, BFormTimepicker, BModal} from "bootstrap-vue";
import { format } from "date-fns"
import FileSaver from "file-saver";

export default {
	name: "DocumentDetails",
	
	components: {
		FormControls,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	
	props: {
		// props from vue router route params
		documentId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initDocument();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localDocument: {
				_id: "",
				documentNo: "",
				name: "",
				fileName: "",
				type: "",
				url: "",
				createdAt: "",
				createdBy: "",
				descriptions: "",
				// added properties
				createdByName: "",
				updatedByName: "",
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				name: "",
				type: "",
			},
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			documentNo: "",
			documentNoInvalid: false,
		}
	},
	
	computed: {
		document() {
			return this.$store.state.contents.documents.find(({ _id }) => _id === this.documentId);
		},
		
		savable() {
			// fall through pattern
			if (!this.document) return true;
			// can safely access properties inside this.document due to the first if clause in this function
			const keys = ["name", "type", "fileName", "descriptions"];
			return keys.some(key => this.localDocument[key] !== this.document[key]);
		},
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.documentNo = "";
				this.documentNoInvalid = false;
			}
		},
		
		initDocument() {
			if (this.document) {
				const obj = {...this.document};
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// createdAt and updatedAt must present in the database, need no nullish check
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				// added properties
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				this.localDocument = obj;
			} else {
				this.localDocument._id = uuidv4();
				this.localDocument.documentNo = "DOC-" + this.localDocument._id.split("-")[0].toUpperCase();
			}
		},
		
		uploadDocument(file) {
			this.localDocument.fileName = file[0].name;
		},
		
		async downloadDocument() {
			FileSaver.saveAs(this.localDocument.url, this.localDocument.fileName);
		},
		
		updateDocument(key, value) {
			this.localDocument[key] = value;
			// check if value is just white spaces
			this.invalidInputs[key] = value.trim().length > 0 ? "" : "invalid";
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const keys = ["name", "type", "fileName"];
			keys.forEach(key => {
				if (!this.localDocument[key] ||
					this.localDocument[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			return Object.keys(this.invalidInputs).some(key => this.invalidInputs[key] === "invalid");
		},
		
		async saveDocument() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["createdByName", "updatedByName", "createdAtDT", "updatedAtDT"];
				const now = new Date().toISOString();
				const document = {...this.localDocument};
				if (!document.createdAt) {
					document.createdAt = now;
					document.createdBy = this.$store.state.user.user._id;
				}
				document.updatedAt = now;
				document.updatedBy = this.$store.state.user.user._id;
				deleteKeys.forEach(key => delete document[key]);
				await this.$store.dispatch("contents/updateContent", { key: "documents", value: document});
				if (this.documentId) {
					this.initDocument();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/supplier/document/${document._id}`);
					// need to run initDocument after route change since documentId prop was undefined
					this.initDocument();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Document Created!";
				}
			}
		},
		
		async deleteDocument(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.documentNo !== this.document.documentNo) this.documentNoInvalid = true;
			} else if (this.documentNo !== this.document.documentNo) {
				bvModalEvent.preventDefault();
				this.documentNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "documents", _id: this.document._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/supplier/document");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/supplier/document");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
</style>